function Header() {
  return (
    <div className="navbar">
      <div className="container">
        <img alt="abakus logo" src="abakus_logo_black.png" />
      </div>
    </div>
  );
}

export default Header;
